.news-overview__wrapper{
  padding: 150px 0 75px;
  .news-overview__inner{
    padding: 15px;
    &--border{
      border: solid 0.8px $teaser-item-border;
    }
  }
  .news-overview{
    &__image{
      object-fit: cover;
      height: auto;
      width: 100%;
    }
    &__title{
      @include font-h3;
      color: $color-camo-green;
    }
    &__date{
      @include copy-s-black-bold;
      color: $color-camo-green;
    }
    &__text{
      @include copy-s-black-regular;
      p {
        @include multiLineEllipsisNewsOverview($lineHeight: 26px, $lineCount: 3, $bgColor: white);
        margin-bottom: 10px;
      }
    }
    &__read-more{
      &--style{
        @include  copy-s-black-bold;
        color:$color-cherryred;
        .icon-rue_m{
          fill: $color-cherryred;
          display: inline-block;
          margin-right: 16px;
          @media only screen and (min-width: 768px) {
            position: relative;
            top: 1.5px;
          }
        }
        &:hover{
          text-decoration: none;
          color: $color-camo-green;
          .icon-rue_m{
            fill: $color-camo-green;
          }
        }
      }
    }
  }
}