#finder {
  .reset {
    span {
      display: block;
      float: left;

      &.icon {
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-right: 5px;
      }

      &.resetFilter {
        cursor: pointer;
        display: flex;
        float: right;
        @include media-breakpoint-up(xl) {
          float: left;
          margin: 10px 5px;
        }
      }
    }
  }
}

.finder, .property-list {
  &__search {
    padding: 30px 0;
  }

  &__map {

  }

  &__attributes {
    padding: 45px 0 35px 0;
    border-bottom: 1px solid $color-grey;
    margin-bottom: 35px;

    .show-map {
      .text {
        display: block;
        float: right;
        line-height: 40px;
      }

      .control {
        display: block;
        float: right;
        margin-top: -6px;
        margin-left: 10px;
      }

      input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      label {
        cursor: pointer;
        text-indent: -9999px;
        width: 30px;
        height: 8px;
        background: $color-grey;
        display: block;
        border-radius: 8px;
        position: relative;
      }

      label:after {
        content: '';
        position: absolute;
        top: -4px;
        left: 0;
        width: 16px;
        height: 16px;
        background: $color-cherryred;
        border-radius: 16px;
        transition: 0.3s;
        -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
      }

      input:checked + label {
        background: #F18289;
      }

      input:checked + label:after {
        left: calc(100%);
        transform: translateX(-100%);
      }

      label:active:after {
        width: 30px;
      }
    }

    input.input {
      width: 100%;
      line-height: 40px;
      padding: 0;
      margin: 0;
      border: 1px solid $color-attribute-border;
      border-radius: 0.25rem;
      text-align: center;
      color: $color-black;
    }

    .bootstrap-select {
      width: 100% !important;
      padding: 0;
      margin: 0;
    }

    .bootstrap-select .btn-light.dropdown-toggle {
      border: 1px solid $color-attribute-border;
      width: 100%;
      margin: 0 0 2px 0;
      padding: 0;
      @include media-breakpoint-up(xl) {
        margin: 0;
      }
    }

    .bootstrap-select .dropdown-toggle .filter-option {
      margin: 0;
      padding: 0;
    }

    .dropdown-toggle {
      .filter-option {
        position: relative;
      }
    }

    .filter-option-inner-inner {
      font-style: normal;
      text-align: center;
      font-size: $font-size-base;
      line-height: 40px;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .bootstrap-select .dropdown-toggle:before {
      display: none;
    }

    .bootstrap-select .dropdown-menu {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      border-radius: 0.25rem;
    }

    .dropdown-item.active, .dropdown-item:active {
      background: #fff;
      color: $color-black;
    }

    .bootstrap-select .dropdown-menu li a:hover {
      background: $color-list-beige;
    }

  }
}
.finder__results,
.property-list,
.project-property-export-admin__results-wrapper
{
  .top {
    padding-bottom: 30px;
  }

  .head {
    background: $color-list-beige;
    padding: 10px 15px;
    font-weight: bold;
    margin-bottom: 15px;

    .col-result {
      > .row {
        > div {
          @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;

            &.col-result__project,
            &.col-result__projectLocation,
            &.col-result__number {
              justify-content: flex-start;
            }

            &.col-result__livingSpace,
            &.col-result__price {
              justify-content: flex-end;
            }
          }
        }
      }
    }
    .click {
      cursor: pointer;

      span {
        float: left;
      }

      &.active {
        color: $color-cherryred;
      }
    }

    .title {
      margin-right: 5px;
    }

    .asc svg, .desc svg {
      fill: $color-cherryred;
    }

    .asc {
      float: left;
      margin-top: 7px;

      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .desc {
      float: left;
      margin-top: 1px;
    }
  }

  .results {
    .property {
      display: block;
      width: 100%;
      padding: 0 15px;
      border-bottom: 1px solid $color-list-border;

      &.open {
        background: $color-list-beige;
      }

      .clickable {
        cursor: pointer;
        padding: 12px 0;

        .icon-minus {
          display: none;
        }
      }

      .details {
        padding: 45px 0 15px;

        @include media-breakpoint-up(lg) {
          padding: 45px 39px 15px 0;
        }

        .location {
          padding-bottom: 25px;
          display: flex;

          @include media-breakpoint-down(xs) {
            margin-top: 10px;
          }
          span {
            display: block;
            float: left;

            &.icon {
              width: 35px;
              height: 35px;
            }

            &.address {
              color: $color-dark-red;
              font-weight: bold;
              padding: 4px 0 0 5px;
            }

            svg {
              fill: $color-cherryred;
            }
          }
        }

        .buttons {
          padding-bottom: $grid-gutter-width/2;
        }

        .detail-prices {
          padding-bottom: $grid-gutter-width/2;
        }
        .floorplan-image {
          padding-bottom: $grid-gutter-width/2;
        }

        .project {
          padding-top: 15px;

          a.btn {
            font-weight: bold;
            line-height: 28px;
          }
        }

        a.fancybox {
          display: inline-block;
          position: relative;

          .zoom {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 31px;
            height: 31px;
            background: $color-cherryred;

            .icon {
              display: block;
              margin: 0 auto;
              margin-top: 4px;
            }

            svg {
              fill: $color-white;
            }
          }
        }

      }
    }
  }

  .col-result-first {
    width: 5%;
    text-align: center;
    @include media-breakpoint-down(sm){
      > div {
        margin-left: -14px;
      }
    }

    span {
      float: left;
      margin-left: 15px;

      svg {
        fill: $color-cherryred;
      }
    }
  }
  .col-result {
    width: 90%;
  }
  .col-result-last {
    width: 5%;
    text-align: center;

    span {
      margin: 0 auto;
    }
  }

}

.location.bootstrap-select {
  .dropdown-item {
    span.text {
      margin-left: 10px;
    }
    &:before {
      content: "";
      background-repeat: no-repeat;
      display: inline-block;
      height: 1em;
      width: 15px;
      vertical-align: text-top;
      background-size: contain;
      background-position: center center;
    }
  }
  .dropdown-item.project {
    &:before {
      background-image: url("/static/icomoon/SVG/haus_m.svg");
    }
  }
  .dropdown-item.project-title {
    &:before {
      background-image: url("/static/icomoon/SVG/wohnimmobilie_m.svg");
    }
  }
  .dropdown-item.region {
    &:before {
      background-image: url("/static/icomoon/SVG/wohnflaeche_m.svg");
    }
  }
  .dropdown-menu.inner {
    li:nth-child(n+5) {
      //display: none;
    }
    li {
      a {
        &.project-title {
          //display: none;
        }
        &.region {
          //display: none;
        }
        &.level-2 {
          padding-left: rem-calc(48);
        }
      }
    }
  }
}


.property-search {
  &__results {
    position: relative;
    min-height: 200px;

    @include media-breakpoint-up(xl) {
      min-height: 500px;
    }

    .sk-folding-cube {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
  }
}

.sk-folding-cube {
  display: none;

  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-cherryred;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.property-list {
  .results {
    .project {
      display: none;
    }
  }
}

.mobile-labels {
  color: $color-dark-grey;
}

@include media-breakpoint-down(sm) {
  .floorplan-image {
    display: none;
  }
  #finder {
    .finder__results {
      .col-result-first {
        span.icon {
          margin-left: 0;
        }
      }
    }
  }
}