.area-contactpersonlist {
  .brick__header{
    margin-bottom: 20px;
    &--headline {
      &-inner{
        margin-bottom: 74px;
      }
    }
    &--sub-headline {
      &-inner {
        @include font-teaser-sub-headline;
      }
    }
    &--info-text{
      margin-top: 20px !important;
      @include  font-teaser-text;

    }

  }

  .contact-person {
    margin: 15px 0;

    &__items{
      text-align: center;
      margin-bottom: 60px;
    }


    .teaser-object{
      display: flex;
      &__info{
        &-wrapper{
          display: flex;
          flex-direction: column;
        }

      }

      &__image{
        &-person {
          height: 100px;
          width: auto;
          border-radius: 50%;
          margin-right: 20px;
        }
      }
      &__position{
        order: 4;
        p{
          margin-bottom: 0 !important;
        }
      }
      &__phone{
        order: 5;
        display: block;
        @include  font-teaser-footer;
      }
      &__sub-headline{
        order: 3;
        display: block;
        @include  font-teaser-footer;
      }
      &__headline{
        order: 2;
        display: block;
        @include  font-teaser-text;
      }
      &__mail{
        order: 6;
        display: block;
        @include  font-teaser-footer;
      }
    }
  }
}