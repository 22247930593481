.home {
  header {
    height: 8.13rem;
    line-height: 8.13rem;
    padding-top: 0;
    @include transition(all 300ms);

    :not(.is-top) {

      .logo-wrapper {

        position: relative;

        @media(min-width: 300px) {
          margin-left: 2rem;
        }


        text-align: center;
        width: 100%;

        a {
          width: 100%;
        }

        &__logo {
          width: 100%;
          max-height: 5rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @media (max-width: 500px) {
            margin-top: 0;
          }
        }
      }
    }
    .top-navigation-wrapper {
      padding-top: 0.5rem;
    }

    &.editmode {
      position: relative;
    }
  }
}

body:not(.home) {

  .logo-wrapper{
    text-align: center;
    a{
      width: 100%;
    }
  }



  header.smart-hide:not(.is-top) {

    line-height: 0px;

    .logo-wrapper__logo {
      opacity: 1;
      @media(max-width: 500px){
        top: 0.6rem;
        width: 100%;
        margin-left: 1rem;
      }
    }
  }

  header.smart-hide.is-top {
    .logo-wrapper__logo{
      @media (max-width: 500px) {
        top: 0.6rem;
        margin-left: 1rem;
        }
    }
  }
}


header {
  position: fixed;
  top: 0;
  z-index: 200;
  max-width: 1920px;
  width: 100%;
  height: $header-height-desktop;
  background-color: #FFFFFF;
  box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);
  left: 50%;
  @include translate-rotate(-50%,0,0);
  padding-top: 0.5rem;
  @include transition(all 300ms);

  &.animated {
    @include transition(all 250ms);
  }

  &.nav-up {
    top: -8.75rem;
    &.open {
      top: 0;
    }
  }

  .logo-wrapper {

    a {
      display: inline-block;
    }

    &__logo {
      max-height: 100%;
      display: block;
      top: 0.6rem;
      position: relative;
      width: 100%;

      @include transition(opacity 250ms);
      opacity: 1;

      &--slim {
        @include transition(opacity 250ms);
        opacity: 0;

      }
    }
  }

  &.top-navigation {
    // height: $extended-header-height-desktop;
    &.nav-up {
      top: -$extended-header-height-desktop;
      &.open {
        top: 0;
      }
    }
    .logo-wrapper {
      height: ($extended-header-height-desktop - ($header-space * 2));
    }
    .main-navigation {
      margin-top: $top-nav-space;
    }
  }
  .top-navigation-wrapper {
    height: $top-nav-height;
    text-align: right;
    line-height: $top-nav-height;

    .search-bar {
      display: inline-block;
    }
    .top-navigation {
      display: inline-block;
      position: relative;
      &__list {

      }
      &__item {
        background-color: $color-cherryred;
        border-color: $color-cherryred;
        width: 6.5rem;
        height: 2.1rem;
        margin-right: 1.19rem;
        margin-left: 1.88rem;

        a {
          color: white;
          font-size: 1.13rem;
          font-weight: bold;
          position: relative;
          top: -4px;
        }
      }
    }
    .language-switch {
      display: inline-block;
      position: relative;
      color: #b7b6b0;
      a {
        color: $color-pinkish-grey;
      }
    }
  }

  /**
  ** CHANGE HEADER IMAGE DEPENDING ON /PATH
  */
  &.smart-hide {
    &.is-top {
      .logo-wrapper__logo {
        opacity: 1;

        &--slim {
          opacity: 0;

        }
      }
    }

    &:not(.is-top) {
      height: $header-height-desktop;


        line-height: $header-height-desktop;


      .logo-wrapper__logo {
        opacity: 0;

        &--slim {
          opacity: 1;

        }
      }

      ~ .header-stage {
        margin-top: $header-height-desktop;
      }
    }
  }
}

.main-navigation {
  &__item {
    &--sub-navigation {
      display: none;
    }
  }
}