.brick__header--headline {
  .h1{
    @include  font-h1;
  }
  .h2{
    @include  font-h2;
  }
  .h3, .h4 {
    color: $color-camo-green;
  }
  &__beige {
    background-color: $color-offwhite;
    margin-bottom: 74px;
    .brick__header--headline-inner {
      line-height: 44px !important;
      @include font-teaser-headline;
    }
  }
}

.brick__header--sub-headline {
  .h1, .h2 {
    color: $color-cherryred;
    font-weight: 700;
  }
  .h3, .h4 {
    color: $color-camo-green;
  }
}

.brick__header--info-text {
  color: $color-camo-green;
  margin-top: 2rem;
  font-weight: $font-weight-bold;
}

.brick__header {
  margin-bottom: 40px;
}