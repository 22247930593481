#siteWrapper {
  max-width: $site-wrapper-width;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  &.top-navigation {
  }
}

span.icon {
  width: 15px;
  height: 15px;
  display: block;

  svg {
    max-width: 100%;
    max-height: 100%;
    //width: 100%;
    //height: 100%;
  }
}

.btn {
  &.btn-primary {
    background-color: $color-cherryred;
    border: none;
    position: relative;
    //border-radius: 0;

    &:hover, a:hover {
      border: none;
      color: $color-camo-green;
      text-decoration: none;

      &:after {
        opacity: 1;
      }
    }

    &:focus, &:active {
      //@include red-gradient-bg;
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &.btn-secondary {
    background-color: $color-camo-green;
    border: none;

    &:hover {
      color: $color-cherryred;
    }
  }

  &.btn-ghost {
    border: 1px solid black;
    color: $color-camo-green;

    &:hover {
      color: $color-cherryred;
    }
  }

  &.btn-icon-white {
    background: $color-white;
    border: 1px solid $color-btn-white-border;
    color: $color-cherryred;
    padding: 8px 10px;

    span {
      display: block;
      float: left;
      line-height: 28px;
      font-weight: bold;

      &.icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      svg {
        fill: $color-cherryred;
      }
    }

    &:hover {
      border: 1px solid $color-cherryred;
    }
  }
}

.location-address {
  &__email {
    padding-top: 15px;

    a {
      position: relative;
      padding-left: 25px;
      color: $color-cherryred;
      @include transition(all 300ms);

      svg {
        fill: $color-cherryred;
        @include transition(all 300ms);
      }

      &:hover {
        @include transition(all 300ms);
        color: $color-dark-red;
        text-decoration: none;

        svg {
          @include transition(all 300ms);
          fill: $color-dark-red;
        }
      }
    }

    span.icon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

body {
  &[data-layout="messe"],
  &[data-layout="fair"]
  {
    .d-none-layout-fair {
      display: none !important;
    }

    span.icon {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}