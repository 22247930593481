.area-map {
  &.map-shown {
    @include transition(all 300ms);
    margin-top: $header-height-desktop;
  }
  .finder__attributes {
    border-bottom: 1px solid transparent;
  }
}

.project-map {
  height: 465px;
  width: 100%;
  overflow: hidden;
}

.map {
  &__title {
    color: $color-cherryred;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 10px;
    display: block;
    &:hover {
      color: $color-cherryred;
    }
  }
  &__address,
  &__price,
  &__rooms{
    position: relative;
    font-size: 16px;
    margin: 10px 0;
    margin-left: 8px;
    font-weight: 500;
    left: 25px;
    height: 20px;
    svg {
      height: 20px;
      width: 27px;
      top: -3px;
      position: absolute;
      left: -30px;
    }
  }
  &__image {
    position: relative;
  }

  &__exit {
    //content: '\f00d';
    //font-family: "Font Awesome 5 Free";
    height: 20px;
    width: 20px;
    position: absolute;
    display: block;
    right: 0;
    z-index: 1;
    font-size: 16px;
    &:hover {
      cursor: pointer;
      color: $color-cherryred;
    }
  }
  &__price,
  &__rooms {
    left: 30px;
    svg {
      left: -35px;
    }
  }

  &__hr {
    height: 2px;
    width: 98%;
    background-color: $color-attribute-border;
    position: relative;
    display: block;
    margin: 20px auto;
  }


  &__btn {
    position: relative;
    height: 40px;
    .btn {
      right: 8px;
      position: absolute;
      font-weight: 600;
    }
  }
}

.popup {
  &__hidden {
    display: none !important;
  }
}


// POPUP STYLING
.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}
/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: /* TIP_HEIGHT= */ 40px;
  left: 0;
}
/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: /* TIP_HEIGHT= */ 8px solid white;
}
/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
  min-width: 200px;
}

.marker{
  height: 20px;
  width: 20px;
  background-image: url('/static/img/favicon/favicon-16x16.png');
  //background-color: red;
}


