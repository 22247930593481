$screen-xxl: 1600px;

/* === Bootstrap Global === */
$enable-rounded: true;
$site-wrapper-width: 1920px;

/* === Header / Navigation === */
$header-height-desktop: 60px;
$extended-header-height-desktop: 130px;
$header-space: 10px;
$top-nav-height: 40px;
$top-nav-space: 20px;


/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$color-dark-grey: #777;
$color-primary: #007bff;
$color-grey: #eee;
$color-cherryred: #e30018 ;
$color-pinkish-grey: #b7b6b0;
$color-offwhite: #f5f5ed;
$color-camo-green: #482b1e;
$color-beige: #e1ded7;
$teaser-item-border: #e1ded6;
$color-dark-red: $color-camo-green;
$color-red: #800c0f;
$color-list-beige: #F7F6F1;
$color-list-border: #D1C2BF;
$color-btn-white-border: #F2F1ED;
$color-attribute-border: #C4C3BE;

$brand-primary: $color-red;

/* === Bricks === */
$brick-padding: 75px;
$brick-in-brick-padding: 20px;
$brick-header-bottom-space: 30px;
$brick-footer-top-space: 30px;

$font-weight-medium: 600;

$font-size-base: 1rem;
$font-size-small: 0.9375rem;

/* === Stage === */
$fix-slider-height: 15rem;
$fix-slider-height-desktop: 35rem;
$stage-text-color: $color-white;
$stage-navigation-color: $color-white;
$stage-navigation-active-color: $color-primary;