.select-wrapper {
  border-radius: 0.25rem;
  select {
    border: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-select-label {
    display: none;
  }

  #contact_salutation {
    //height: 2.25rem;
  }
}


.area-contact-form {
  .form-control {
    color: $color-pinkish-grey;
  }
  .form-group {
    position: relative;
    padding-top: 15px;

    .form-check {
      padding-top: 0;
      .form-check-input {
        width: 15px;
        height: 15px;
      }
      label {
        position: relative;
        top: 0;
        left: 0;
        color: black;
        font-size: 0.75rem;
      }
    }

    a {
      color: $color-cherryred;
      font-size: 0.75rem;
    }

    .select-wrapper {
      border: 1px solid $color-pinkish-grey;
      border-radius: 0.25rem;
      select {
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      label {
        top: 1px;
      }

      .form-select-label {
        display: none;
      }

      #contact_salutation {
        height: 2.25rem;
      }
    }

    label {
      position: absolute;
     // top: 22px;
      top: 1px;
      left: 23px;
      background: #fff;
      padding: 0 5px;
      transition: top .2s;
      color: $color-pinkish-grey;
    }
/*
    input, textarea {
      border: 1px solid $color-pinkish-grey;
      box-shadow: none;
      &::placeholder {
        opacity: 0;
      }
      &:focus, &:not(:placeholder-shown) {
        & + label {
          top: 1px;
        }
      }
    }
*/
    input:not([type=checkbox]), textarea { // https://css-tricks.com/float-labels-css/
      border: 1px solid $color-pinkish-grey;
      box-shadow: none;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: transparent;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: transparent;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: transparent;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: transparent;
      }

      /**
      * When the element is focused, remove the label transform.
      * Also, do this when the placeholder is _not_ shown, i.e. when
      * there's something in the input at all.
      */

      &:focus + label {
        top: 1px !important;
      }
      &:not(:placeholder-shown) + label {
        top: 1px !important;
      }
      &:not(:-ms-input-placeholder) + label {
        top: 1px !important;
      }

      &:placeholder-shown + label {
        top: 22px;
      }
      &:-ms-input-placeholder + label {
        top: 22px;
      }
    }

  }
}