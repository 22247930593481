// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}

@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}

// font sizes flow mixin
@mixin flow-font-size($flow-variable, $flow-index: auto) {
    $flow-length: length($flow-variable);

    @if $flow-index == "auto" {
        @include flow-font-size($flow-variable, 1);

        @include media-breakpoint-up(sm) {
            @include flow-font-size($flow-variable, 2);
        }

        @include media-breakpoint-up(md) {
            @include flow-font-size($flow-variable, 3);
        }

        @include media-breakpoint-up(lg) {
            @include flow-font-size($flow-variable, 4);
        }

        @include media-breakpoint-up(lg) {
            //@include flow-font-size($flow-variable, 5);
        }

    } @else if $flow-index >= $flow-length {
        font-size: nth($flow-variable, $flow-length);
    } @else {
        font-size: nth($flow-variable, $flow-index);
    }
}

// Icon
@mixin icon-fill($fill: #FFFFFF) {
    fill: $fill;
}

@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

@mixin fa-icon($fa-font-size-base: 15px, $fa-line-height-base: 1) {
    display: inline-block;
    font: normal normal normal $fa-font-size-base/$fa-line-height-base FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin red-gradient-bg() {
    background: linear-gradient(to right, #800c0f, #800c0f 0%, #a9000e);
}


/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    // text-align: justify;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 15px;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
@mixin multiLineEllipsisNewsOverview($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}