.similiar-objects__headline {
  color: $color-cherryred;
  margin-top: 60px;
}

#similiarObjects {
  .resultList {
    .property:nth-child(n+7) {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
}