.newsPage{
  margin-top: 10rem;
  &--header {
    color: $color-cherryred;

  }
  .h1, .h2 {
    color: $color-cherryred;
  }
  .h3, .h4 {
    color: $color-camo-green;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}