footer {
  padding: $brick-padding 0 0;

  .footer-contact {
    background-color: $color-beige;
    padding: $brick-padding 0;

    &__address {
      color: $color-camo-green;

      img {
        margin-bottom: 15px;
      }
    }
  }

  .footer-bottom {
    background: $color-cherryred;
    @include red-gradient-bg;
    padding: 18px 0;

    .social-media-links {
      a {
        color: $color-white;
        font-size: 18px;

        &:hover {
          color: $color-camo-green;
        }
      }
    }
  }

  .footer-legal-links {
    ul {
      margin-bottom: 0;

      li {
        a {
          color: $color-white;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
          position: relative;
          display: block;

          &:hover{
            text-decoration: none;
            color: $color-camo-green;
          }
        }

        &:not(:last-child):not(:only-child) {
          a:after {
            content: "|";
            display: inline-block;
            padding-left: 10px;
          }
        }
      }
    }
  }

  .footer-locations {

    &__item {
      color: $color-camo-green;
      font-weight: $font-weight-medium;
      font-size: $font-size-small;

      &:not(.active) {
        display: none;
      }

      a:not(.email) {
        @include transition(all 300ms);
        color: $color-camo-green;

        &:hover {
          text-decoration: none;
          color: $color-cherryred;
          @include transition(all 300ms);
        }
      }
    }

    &__map {
      position: relative;

      &-pin {
        width: 15px;
        height: 15px;
        margin-left: -7.5px;
        margin-top: -7.5px;
        background-color: $color-camo-green;
        border-radius: 50%;
        @include transition(all 300ms);
        cursor: pointer;
        position: absolute;

        &:hover, &.active {
          background-color: $color-cherryred;
          @include transition(all 300ms);
        }

        &.active {
          .footer-locations__map-arrow {
            opacity: 1;
            visibility: visible;
            @include transition(all 300ms);
          }
        }
      }

      &-arrow {
        position: absolute;
        bottom: 15px;
        right: 15px;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        @include transition(all 300ms);
      }
    }
  }
}
