.divider-after {
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
    width: 2px;
    border-left: white 2px solid;
    right: -1px;
  }
}
.natural-search {
  position: relative;
  padding: 15px 0;
  width: 48%;
  @include media-breakpoint-up(xl){
    //min-width: 900px;
  }
  &__inner {
    max-width: 90%;
    position: relative;
  //top: 50%;
   // transform: translate(0, -50%);
    margin: 0 auto;
    @include media-breakpoint-up(lg){
      max-width: 95%;
    }

    @media screen and (min-width: $screen-xxl) {
      max-width: rem-calc(650);
    }
  }
  &__headline {
    font-weight: 700;
    font-size: 1.1rem;
    color: $color-cherryred;
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }
  &__text {
    vertical-align: sub;
    @include media-breakpoint-up(lg){
      font-size: 20px;
      line-height: 2;
    }
  }
  &__select-wrapper {
    position: relative;
    display: inline-block;
    span.icon {
      width: 12px;
      height: 12px;
      right: 6px;
      bottom: 9px;
      position: absolute;
    }
  }
}

.quick-search {
  position: relative;
  padding: 15px 0;
  width: 50%;
  z-index: 10;
  @include media-breakpoint-up(xl){
   // width: 30%;
    //min-width: 600px;
  }
  &__inner {
    max-width: 90%;
    position: relative;
   // top: 50%;
  //  transform: translate(0, -50%);
    margin: 0 auto;
    @include media-breakpoint-up(xl){
      max-width: 50%;
    }
  }
  &__headline {
    font-weight: 700;
    font-size: 1.1rem;
    color: $color-cherryred;
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }
  &__region {
    width: 100%;
    outline: none;
    box-shadow: none;
    border-radius: 4px;
    border: none;
    &:focus, &:hover {
      outline: none;
      box-shadow: none;
    }
    &.bootstrap-select {
      width: 100% !important;
      .dropdown-menu.show {
        min-width: unset !important;
        width: 100% !important;
        transform: translate(0) !important;
      }
    }
  }
  &__select {
  }
  &__submit {
    margin-top: 10px;
    //width: 32%;
  }
}

.natural-search, .quick-search {
  &__select {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .dropdown-menu {

    }
    .dropdown-toggle {
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
      &:after {
        margin: 0;
        vertical-align: unset;
        position: absolute;
        bottom: 5px;
        right: 0;

      }
      .filter-option {
        position: relative;
        padding: 0;
      }
    }

    .dropdown-menu {
      padding: 0;
      .active {
        background-color: $color-offwhite;
        color: $color-dark-grey;
      }
      .disabled {
        display: none;
      }
      .dropdown-item {
        text-align: center;
      }
    }
  }
  &__submit {
    @include media-breakpoint-down(sm) {
      font-size: 0.95em;
    }
  }
}

.quick-search__select {
  max-width: 32%;
  width: 100%;
  .btn-light.dropdown-toggle {
    background: $color-white;
    border: none;
    &:disabled, &:hover {
      background: $color-white;
      border: none;
    }
  }
  &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {

  }
  .dropdown-toggle {
    &:after, &:before {
      display: none;

    }
  }

  .dropdown-toogle {
    .filter-option, .filter-option-inner {
      padding-right: 0;
    }
  }
  .filter-option-inner-inner {
    text-align: center;
    color: $color-dark-grey;
  }
}

.natural-search__select {
  .btn-light.dropdown-toggle {
    background: transparent;
    border: none;
    &:disabled, &:hover {
      background: transparent;
      border: none;
    }
  }
  &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    //width: 250px;
    width: auto;
    padding: 0 0.1rem;
  }
  .dropdown-toggle {
    padding: 0 5px;
    padding-right: 25px;
    margin-right: 5px;
    border: none;
    background: transparent;
    outline: none;
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    min-width: 70px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      bottom: 3px;
      height: 1px;
      width: calc(100% - 5px);
      border-bottom: $color-dark-grey solid 1px;
    }
    &:after {
      content: "";
      border: none;
      margin: 0;
      vertical-align: unset;
      position: absolute;
      bottom: 5px;
      right: 0;
      width: unset;
      height: unset;
    }
  }

  .filter-option-inner-inner {
    font-style: italic;
    //color: $color-dark-grey;
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  button[data-id="region"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(193);
    }
  }
  button[data-id="who"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(229);
    }
  }
  button[data-id="type"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(155);
    }
  }
  button[data-id="purpose"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(166);
    }
  }
  button[data-id="lifestyle"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(304);
    }
  }
  button[data-id="infrastructure"] {
    @include media-breakpoint-up(xl){
      min-width: rem-calc(340);
    }
  }
}

.stage-snippet:not(.stage-snippet--mainPage) {
  .natural-search {
    display: none;
  }
  .quick-search {
    min-width: unset;
    width: 100%;
  }
  .divider-after {
    &:after {
      display: none;
    }
  }
}

//@media (max-width: 1500px) {
@include media-breakpoint-down(sm){
  .natural-search {
    min-width: unset;
    width: 100%;
  }
  .quick-search {
    min-width: unset;
    width: 100%;
  }
  .divider-after {
    &:after {
      display: none;
    }
  }
}