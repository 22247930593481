.area-teaser {
  padding: 40px 0;
  .h2{
    @include  font-h2;
  }
  .h1{
    @include  font-h1;
  }

  .teaser-items {
    .pimcore_tag_block {
      display: flex;
      flex-wrap: wrap;
    }
    &--twoColumn {
      .pimcore_block_entry {
        width: 50%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--threeColumn {
      .pimcore_block_entry {
        width: 33.3333333333%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--panorama {
      .pimcore_block_entry {
        width: 100%;
        clear: none;
        padding: 0 15px;
      }
    }
  }

  .panorama__arrows{
    opacity: 0.3;
    cursor: pointer;
    outline: none;
    width: 30px;
    .carousel-control-prev{
      left: -150px;
      .icon{
        width: 25px;
        height: 25px;
      }
    }
    .carousel-control-next{
      right: -150px;
      .icon{
        width: 25px;
        height: 25px;
      }
    }
  }


  &__items {
  //  padding: 20px;
    .teaser-item{
      padding-bottom: $grid-gutter-width/2;

      @include media-breakpoint-up(xl) {
        padding-bottom: $grid-gutter-width;
      }
    }

    .teaser-object {
      display: flex;
      flex-direction: column;
      &--default{
        background-color: $color-white;
        border: solid 0.8px $teaser-item-border;

        .teaser-object {
          &__label {
            display: block;
            top: rem-calc(4);
          }
        }
      }
      &--default.teaser-object--has-logo {
        .teaser-object {
          &__label {
            top: rem-calc(85);
          }
        }
      }

      &--panorama-wrapper{
        background-color: $color-white;
        padding-top: 15px;
      }
      &--panorama-justify{
        border: solid 1.1px $teaser-item-border;
        @media (min-width: 320px) and (max-width: 1366px) {
          overflow: hidden;
        }
      }
      &__image {
        order: 2;
        height: 100%;
        width: 100%;
        img{
          object-fit: cover;
          height: 100%;
          width: 100%;
          @media (min-width: 320px) and (max-width: 768px) {
            width: 100%;
          }
        }
      }
      &__label {
        display: none;
        position: absolute;
        top: rem-calc(4);
        @include font-h6;
        font-weight: bold;
        color: $color-white;
        padding: rem-calc(5) rem-calc(10) rem-calc(6);
        background: rgba($color-cherryred, 0.9);
        min-width: rem-calc(130);
        text-align: center;
      }
      &__sub-headline {
        @include  font-h6;
        color: $color-camo-green;
        padding-bottom: 10px;
      }
      &__headline {
        order: 3;
        @include  font-h3;
        padding-bottom: 10px;
      }
      &__text {
        @include  font-teaser-footer;
        order: 4;
        padding: 0 0 16px 0;
      }
      &__link {
        order: 5;
        padding: 16px 0 0;
        @include  copy-s-black-bold;
        color:$color-cherryred;

        .icon-rue_m{
          fill: $color-cherryred;
          display: inline-block;
          margin-right: 8px;
        }
        &:hover{
          text-decoration: none;
          color: $color-camo-green;
          .icon-rue_m{
            fill: $color-camo-green;
          }
        }
      }
      &__logo{
        height: 80px;
        width: auto;
        margin-bottom: 1px;

        &-wrapper{
          text-align: center;
        }
      }

      &__info{
        &-wrapper{
          order: 3;
          padding: 20px 16px 16px;
        }
      }

      &__label-panorama {
        position: absolute;
        z-index: 500;
        top: 25px;
        left: -140px;
        text-align: center;
        min-width: 262px;
        background-color: $color-camo-green;
        padding: 8px 0;
        @include  font-h3;
        color: $color-offwhite;

      }
      &__text-panorama {
        @include  font-teaser-footer;
        margin-top: 10px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      &__headline-panorama {
        @include  font-h3;
        padding-left: 15px;
      }
      &__link-panorama{
        padding: 0 15px;
        @include  copy-s-black-bold;
        color:$color-cherryred;

        .icon-rue_m{
          fill: $color-cherryred;
          display: inline-block;
          margin-right: 8px;
        }
        &:hover{
          text-decoration: none;
          color: $color-camo-green;
          .icon-rue_m{
            fill: $color-camo-green;
          }
        }
      }
      &__logo-panorama{


        &-wrapper{
          text-align: center;
          img{
            height: 127px;
            width: auto;
            margin: 10px auto;
          }
        }
      }
    }

    &--panorama {
      .teaser-item {
        &.left {
          .teaser-object {
            &__label {
              @include media-breakpoint-up(lg) {
                right: 0;
                left: auto;
              }
            }
          }
        }
      }

      .teaser-object {
        &__sub-headline {
          padding-left: 15px;
        }
        &__label {
          display: block;
          top: rem-calc(44);
          @include media-breakpoint-up(lg) {
            top: rem-calc(4); 
          }
        }
      }
    }

    &--slider {
      position: relative;
      padding: 0 0;
      padding-bottom: 30px;
      .teaser-items__row {
        margin: 0;
        outline: none;
      }
      .teaser-item {
        @include media-breakpoint-up(md) {
          padding-bottom: 0;
        }
      }
      .slick-arrow {
        opacity: 0.3;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        outline: none;
        width: 30px;
        height: 30px;
        img {
          width: 30px;
          height: 30px;
        }
        &.prev {
          left: -90px;
        }
        &.next {
          right: -90px;
        }
      }
    }

    &--masonry {
      margin-left: -15px;
      margin-right: -15px;
      .teaser-item {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
        margin-bottom: 15px;
        float: left;
        &--twoColumn {
          width: 50%;
        }
        &--threeColumn {
          width: 33.333333%;
        }
      }
    }
    .slick-slide{
      height: auto;
    }

    .slick-dots{
      opacity: 0.3;
      li {
        margin: 0 0 3px;
        height: auto;
        width: auto;
        img:nth-child(1) {
          display: block;
          height: 6px;
          margin: 0 4px;
        }
        img:nth-child(2) {
          display: none;
          height: 12px;
        }
        &.slick-active{
          margin-bottom: 0;
          img:nth-child(1) {
            display: none;
            height: 6px;
          }
          img:nth-child(2) {
            display: block;
            height: 12px;
            margin: 0 2px;
          }
        }
      }
    }
  }
  .left .carousel-wrapper {
    display: flex;
    flex-direction: row;
    &__inner{
      order: 2;
    }
  }

  .right .carousel-wrapper {
    display: flex;
    flex-direction: row;
    &__inner{
      order: 1;
    }

  }
  .right .right{
    order: 2;
  }

  .right .teaser-object--panorama .teaser-object--panorama-justify
  .col-lg-8{
    order: 2;
    background: $color-white;
  }
  .right .teaser-object--panorama .teaser-object--panorama-justify
  .teaser-object--panorama-wrapper{
    order: 1;
  }
  .right .teaser-object--panorama .teaser-object__label-panorama{
    left: 810px;
    @media (min-width: 320px) and (max-width: 1200px) {
      left: 630px;
    }
  }
  .right .panorama__arrows .carousel-control-prev{
    left: -150px;
  }
  .right .panorama__arrows .carousel-control-next{
    right: -150px;
  }
  .carousel-inner{
    overflow: unset;
  }

  .carousel.carousel-fade .carousel-inner.carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
  }
  .carousel-fade .carousel-inner.carousel-item .active {
    opacity: 1;
  }
  .carousel-fade .carousel-inner.carousel-item .active.left,
  .carousel-fade .carousel-inner.carousel-item .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
  }
  .carousel-fade .carousel-inner.carousel-item .next.left,
  .carousel-fade .carousel-inner.carousel-item .prev.right {
    opacity: 1;

  }
  .carousel-fade .carousel-control {
    z-index: 2;
  }
  @media screen and (min-width: 768px) and (max-width: 1366px) {
    .carousel-wrapper{
      justify-content: center;
    }
    .carousel-wrapper__inner{
      display: none;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 992px) {
    .right .teaser-object--panorama .teaser-object__label-panorama{
      position: inherit;
    }
    .teaser-object__label-panorama{
      position: inherit;
    }
    .left .teaser-object--panorama .teaser-object--panorama-justify
    .col-lg-8{
      order: 2;
    }
  }
}