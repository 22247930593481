/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: darken($color-cherryred,5%);
    &:hover {
        color: darken($color-cherryred, 10%);
    }
}

.hidden {
    display: none !important;
}

small, .small {
    small, .small {
        font-size: 100%;
    }
}

.sticky {
    position: fixed;
    max-width: $site-wrapper-width;
    top: 0;
    width: 100%;
    z-index: 200;
}

// form error stylings
.has-error {
    .form-control-feedback {
        color: $danger;
    }
    .form-control {
        border-color: $danger;
    }
}
.has-warning {
    .form-control-feedback {
        //color: $warning;
        color: $color-cherryred;
    }
    .form-control {
        //border-color: $warning;
        color: $color-cherryred;
    }
    input[type="checkbox"] {
        ~ label {
            color: $color-cherryred !important;
        }
    }

}
.has-success {
    .form-control-feedback {
        color: $success;
    }
    .form-control {
        border-color: $success;
    }
}

.anchor-inner {
    position: absolute;
    top: -($brick-padding);

    &__container {
        position: relative;
    }

    &--reduced {
        top: -($brick-in-brick-padding);
    }
}

body {
    &.online {
        .d-offline {
            display: none;
        }
    }
    &.offline {
        .d-online {
            display: none;
        }
    }
}


/******************
 * COOKIE POLICY
 ******************/
#pc-cookie-notice {

    #pc-button {
        button {

        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/*----------------------*/
/*	reCAPTCHA BADGE     */
/*----------------------*/

.grecaptcha-badge {
    visibility: hidden;

    &--visible {
        visibility: visible;
    }
}