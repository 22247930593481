form {
  .bootstrap-select {
    .btn,
    .dropdown-menu {
      @include media-breakpoint-down(md) {
        font-size: 13px;
      }
    }
  }
}
.form-control {
  &[type="checkbox"] {
    width: auto;
    height: auto;
  }
}