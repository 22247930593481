@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        @include flow-font-size($size)
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        line-height: $line-height
    }
    margin: 0;
}

@mixin font-h4 {
    font-size: 1.25rem;
    color: $color-camo-green;
    margin-bottom: 15px;
}
@mixin font-h1 {
    @include make-font(40px, 700, $font-family-base, 55px, $color-cherryred);
}

@mixin font-h2 {
    @include make-font(28px, 700, $font-family-base, 38px, $color-cherryred);
}
@mixin font-h3 {
    @include make-font(20px, 700, $font-family-base, 27px, $color-cherryred);
}
@mixin font-h5 {
    @include make-font(18px, 700, $font-family-base, 22px, $color-cherryred);
}
@mixin font-h6 {
    @include make-font(15px, 700, $font-family-base, 18px, $color-cherryred);
}


@mixin copy-s-black-regular {
    @include  make-font(15px, 400, $font-family-base, 26px, $color-black);
}
@mixin copy-s-black-bold {
    @include  make-font(15px, 700, $font-family-base, 26px, $color-camo-green);
}

h4, h4 {
    @include font-h4;
}

@mixin font-teaser-sub-headline {
    @include make-font(28px, 700, $font-family-sans-serif, 38px, $color-cherryred);
}

@mixin font-teaser-text {
    @include make-font(18px, 700, $font-family-sans-serif, 24px, $color-camo-green);
}

@mixin font-teaser-headline {
    @include make-font(20px, 700, $font-family-sans-serif, 27px, $color-camo-green);
}

@mixin font-teaser-footer {
    @include make-font(15px, 400, $font-family-sans-serif, 26px, $color-black);
}
