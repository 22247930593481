.area-wysiwyg {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    h1, h2, h3 {
      color: $color-cherryred;
    }

    p {
      color: $color-camo-green;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    ul > li {
      @include copy-s-black-regular;
      color: $color-camo-green;
      background-image: url("/static/icomoon/SVG/rue_red.svg");
      background-repeat: no-repeat;
      padding-left: 1.5rem;
      background-size: 1rem;
      background-position: 0 6px;
    }
  }
}