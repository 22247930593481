.wysiwyg-special {

  .brick:first-child {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &:first-child {

    & > section {
      border-right: 3px solid $color-beige;
      padding-right: 4rem;
    }
  }

  &:nth-child(2){
    & > section {
      padding-left: 2.5rem;
    }
  }

  .brick__header {
    margin-bottom: 0;

    &--headline {
      margin-bottom: 0;
      padding-bottom: 3.2rem;
    }
  }

  .brick__footer{
    margin-top:3.7rem;
  }
}

